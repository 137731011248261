<template>
  <div class="wrap" v-loading="loading">
    <div class="user-img">
      <div class="avatar">
        <i v-if="!userInfo" class="icon el-icon-user-solid"></i>
        <div v-else class="avatar_img" :style="avatarImgStyle"></div>
        <div v-if="isEdit" class="bottom">
          <el-upload
            name="fileInput"
            :headers="{
              Authorization: token ? 'Bearer ' + token : ''
            }"
            :action="uploadAvatarUrl"
            accept=".jpg,.jpeg,.png"
            :on-progress="onUploadAvatarProgress"
            :on-error="onUploadAvatarError"
            :on-success="onUploadAvatarSuccess">
            <a href="javascript:void(0)">{{$t('page.personal.child_setting.changeAvatar')}}</a>
          </el-upload>
        </div>
      </div>
    </div>
    <div v-if="userInfo" class="user-info">
      <transition name="el-zoom-in-top">
        <el-alert
          v-if="isEditSucShow"
          :title="$t('message.success')"
          type="success"
          effect="dark"
          show-icon
          style="margin-bottom: 20px;">
        </el-alert>
      </transition>
      <el-form ref="form" :model="fmData" :rules="rules" label-width="115px" label-suffix=":">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-form-item :label="$t('model.user.UserTrueName')">
              <div v-if="!isEdit">{{userInfo.UserTrueName}}</div>
              <el-input v-else v-model="fmData.UserTrueName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="20">
          <el-col :span="16">
            <el-form-item label="生日">
              <div v-if="!isEdit">{{userInfo.address}}</div>
              <div v-else>
                <el-select style="width: 120px;">
                  <el-option
                    v-for="item in options.year"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
                <span class="concat-word">年</span>
                <el-select style="width: 120px;">
                  <el-option
                    v-for="item in options.year"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
                <span class="concat-word">月</span>
                <el-select style="width: 120px;">
                  <el-option
                    v-for="item in options.year"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
                <span class="concat-word">日</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="20">
          <el-col :span="16">
            <el-form-item :label="$t('model.user.Gender')">
              <div v-if="!isEdit">{{userInfo.Gender === 0 ? $t('dict.gender[0]') : $t('dict.gender[1]')}}</div>
              <el-select v-else v-model="fmData.Gender" style="width:120px;">
                <el-option
                  v-for="item in options.gender"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="20">
          <el-col :span="16">
            <el-form-item label="地址">
              <div v-if="!isEdit">{{userInfo.address}}</div>
              <div v-else>
                <el-select style="margin-right:8px; width:120px;">
                  <el-option
                    v-for="item in options.year"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
                <el-select style="margin-right:8px; width:120px;">
                  <el-option
                    v-for="item in options.year"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
                <el-select style="width: 120px;">
                  <el-option
                    v-for="item in options.year"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="20">
          <el-col :span="16">
            <el-form-item :label="$t('model.user.Address')">
              <div v-if="!isEdit">{{userInfo.Address}}</div>
              <el-input v-else v-model="fmData.Address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="16">
            <el-form-item :label="$t('model.user.PhoneNo')" prop="PhoneNo">
              <div v-if="!isEdit">{{userInfo.PhoneNo}}</div>
              <el-input v-else v-model="fmData.PhoneNo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="16">
            <el-form-item :label="$t('model.user.Email')" prop="Email">
              <div v-if="!isEdit">{{userInfo.Email}}</div>
              <el-input v-else v-model="fmData.Email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="16">
            <el-form-item>
              <el-button v-if="!isEdit" key="edit" type="primary" @click="onEdit">{{$t('action.edit')}}</el-button>
              <el-button v-else key="save" type="primary" @click="onSave">{{$t('action.save')}}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import pattern from '@/config/pattern'
import userApi from '@/api/user'
import setting from '@/setting'
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      isEdit: false,
      isEditSucShow: false,
      uploadAvatarUrl: `${setting.baseUrl.api}Sys_User/Upload`,
      options: {
        year: (() => {
          let opts = []
          let curYear = new Date().getFullYear()
          for (let i = 1900; i <= curYear; i++) {
            opts.push(i)
          }
          return opts
        })(),
        gender: [
          {key: 0, label: this.$t('dict.gender[0]')},
          {key: 1, label: this.$t('dict.gender[1]')}
        ]
      },
      userInfo: null,
      fmData: null,
      rules: {
        PhoneNo: [
          {pattern: pattern.phone, message: '手机格式不正确', trigger: 'blur'}
        ],
        Email: [
          {required: true, message: '请输入', trigger: 'blur'},
          {pattern: pattern.email, message: '邮箱格式不正确', trigger: 'blur'}
        ]
      }
    }
  },
  computed: {
    ...mapState(['user', 'token']),
    avatarImgStyle() {
      if (!this.userInfo) {
        return ''
      }
      return {
        'background-image': `url('${setting.baseUrl.img}${this.fmData.HeadImageUrl}')`
      }
    }
  },
  inject: ['validateLogin'],
  methods: {
    ...mapActions(['getCurrentUserInfo']),
    getUserInfo() {
      this.loading = true
      userApi.getPageData({
        wheres: JSON.stringify([{
          name: 'Token',
          value: this.token,
          displayType: 'text'
        }])
      })
      .then(res => {
        this.loading = false
        if (!res || !res.rows || !res.rows.length) {
          this.userInfo = null
          return
        }
        this.userInfo = res.rows[0]
        this.fmData = JSON.parse(JSON.stringify(res.rows[0]))
      })
      .catch(err => {
        this.loading = false
      })
    },
    onEdit() {
      this.isEdit = true
    },
    onUploadAvatarProgress() {
      this.loading = true
    },
    onUploadAvatarError(err) {
      this.loading = false
    },
    onUploadAvatarSuccess(res, file) {
      this.loading = false
      if (res.status) {
        this.fmData.HeadImageUrl = res.data + file.name
      }
    },
    onSave() {
      this.$refs['form'].validate(async valid => {
        if (!valid) {
          return;
        }
        this.doSave();
      });
    },
    doSave() {
      this.loading = true
      let model = this.fmData
      userApi.update({
        mainData: {
          Address: model.Address,
          CreateDate: model.CreateDate,
          Creator: model.Creator,
          DeptName: model.DeptName,
          Email: model.Email,
          Enable: model.Enable,
          Gender: model.Gender,
          HeadImageUrl: model.HeadImageUrl,
          PhoneNo: model.PhoneNo,
          Remark: model.Remark,
          Role_Id: model.Role_Id,
          Tel: model.Tel,
          UserName: model.UserName,
          UserTrueName: model.UserTrueName,
          User_Id: model.User_Id
        }
      })
      .then(res => {
        this.loading = false
        if (!res.status) {
          this.$notify.error({
            title: this.$t('message.noticeTitle'),
            message: this.$t('message.failed')
          })
          return
        }
        this.userInfo = JSON.parse(res.data).data
        this.fmData = JSON.parse(res.data).data
        this.isEdit = false
        this.isEditSucShow = true
        setTimeout(() => {
          this.isEditSucShow = false
        }, 3000);
        this.getCurrentUserInfo()
      })
      .catch(err => {
        this.loading = false
      })
    }
  },
  created() {
    this.validateLogin()
  },
  mounted() {
    if (!this.user) {
      this.$router.push(`/auth?type=login&redirect=${this.$route.path}`)
    }
    this.getUserInfo()
  }
}
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  padding: 20px;
  min-height: 300px;
  .user-img {
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 40px;
    .avatar {
      position: relative;
      height: 200px;
      width: 200px;
      background-color: #C0C4CC;
      border-radius: 2px;
      &_img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center;
        background-size: cover;
      }
      .icon {
        height: 100%;
        width: 100%;
        font-size: 200px;
        color: #fff;
      }
      .bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 40px;
        line-height: 40px;
        width: 100%;
        // background-color: rgba(0,0,0,.5);
        background-color: rgba(255,255,255,.8);
        text-align: center;
        a {
          color: #409EFF;
          text-decoration: none;
          font-size: 14px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      /deep/ .el-upload-list {
        display: none;
      }
    }
  }
  .user-info {
    flex-grow: 1;
    .concat-word {
      display: inline-block;
      padding: 0 8px;
    }
  }
}
</style>
